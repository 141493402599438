<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <div v-if="!showLoading">
    <CRow style="margin-bottom:20px">
      <CCol>Topik pengajaran Sion Raya untuk <b>{{currentPeriod}}</b></CCol>
    </CRow>
    <CRow style="width:350px">
      <CCol>      
        <CSelect
          id="selectCity"
          label="Kota"
          :options="cityOptions"
          :value.sync="selectedCity"
          horizontal
          @change="onChangeCity()"
        />
      </CCol>
    </CRow>
    <CRow>
      <CDataTable
        :items="items"
        :fields="fields"
        :items-per-page="30"
        hover
        pagination>
        <template #status="{item}">
          <td>
            <CBadge :color="getBadge(item.role)">
              {{item.status}}
            </CBadge>
          </td>
        </template>
        <template #show_details="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="onClickEdit(item, index)">
              Edit
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CRow>
  </div>

</div>
</template>

<script>
  import api from "../../api/api"

  const fields = [
    { 
      key: 'topic', 
      label: 'Topik', 
      _style: 'min-width:100px'
    },
    { 
      key: 'deliveryDateFormatted', 
      label: 'Tanggal', 
      _style: 'min-width:50px'
    },
    { 
      key: 'preacher', 
      label: 'Pengajar', 
      _style: 'min-width:70px'
    },
  ]

  export default {
    name: 'Fellowship',
    data () {
      return {
        showLoading: false,
        items: [],
        itemsAll: [],
        fields,
        details: [],

        selectedCity: '',
        cityOptions: [],
        cities: []
      }
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else
        this.listCity()
    },

    methods: {
      async listCity () {
        this.showLoading = true
        let apiRes = await api.listCity()
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.cities = apiRes.cities

          this.cityOptions = []
          for(var i=0; i<this.cities.length; i++){
            var option = {value: this.cities[i].id, label: this.cities[i].city}
            this.cityOptions.push(option)
          }

          if(this.cities.length>0){
            this.selectedCity = this.cities[0].id
            this.listBahanPsk()
          }
        } 
        else {
          console.log("listCity ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async listBahanPsk () {
        this.showLoading = true
        let apiRes = await api.listBahanPsk()
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.currentPeriod = apiRes.data.currentPeriodName
          this.itemsAll = apiRes.data.items

          this.onChangeCity()
        } 
        else {
          console.log("listBahanPsk ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      onChangeCity(){
        this.items = []
        for(var i=0; i<this.itemsAll.length; i++){
          if(this.itemsAll[i].cityId===this.selectedCity) this.items.push(this.itemsAll[i])
        }
      },
    }
  }
</script>
